// VerificationPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import { toast } from 'react-toastify';
import styles from './VerificationPage.module.css';

const VerificationPage = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [resendTimer, setResendTimer] = useState(120);
    const [resendEnabled, setResendEnabled] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const [isCodeValid, setIsCodeValid] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) return prevTimer - 1;
                setResendEnabled(true);
                return 0;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleVerification = async () => {
        if (!isCodeValid) {
            toast.error('Please enter a valid 6-digit code.');
            return;
        }

        try {
            const response = await axiosInstance.post('/auth/verify-email', { code: verificationCode });
            toast.success(response.data.message);
            navigate('/dashboard');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error('Invalid verification code. Please try again.');
            } else {
                toast.error('Verification failed. Please try again later.');
            }
        }
    };

    const handleResendCode = async () => {
        if (resendCount >= 5) {
            toast.error('Maximum resend attempts reached. Please contact support.');
            return;
        }

        try {
            const userEmail = localStorage.getItem('userEmail');
            if (!userEmail) {
                toast.error('User email not found. Please log in again.');
                navigate('/login');
                return;
            }

            await axiosInstance.post('/auth/resend-token', { email: userEmail });
            toast.success('New verification code sent to your email.');
            setResendCount(resendCount + 1);
            setResendTimer(120);
            setResendEnabled(false);
        } catch (error) {
            toast.error('Error sending verification code. Please try again later.');
        }
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;
        setVerificationCode(value);
        setIsCodeValid(/^\d{6}$/.test(value));
    };

    return (
        <div className={styles.verificationContainer}>
            <section className={styles.verificationCard}>
                <h1 className={styles.title}>Verify Your Email</h1>
                <p className={styles.instructions}>
                    We've sent a 6-digit verification code to your email. 
                    Enter the code below to confirm your account.
                </p>
                <div className={styles.inputGroup}>
                    <label htmlFor="verificationCode" className={styles.hiddenLabel}>Verification Code</label>
                    <input
                        id="verificationCode"
                        type="text"
                        value={verificationCode}
                        onChange={handleCodeChange}
                        placeholder="Enter 6-digit code"
                        className={`${styles.verificationInput} ${!isCodeValid ? styles.invalidInput : ''}`}
                        maxLength="6"
                        aria-label="Verification Code"
                        required
                    />
                    <button
                        onClick={handleVerification}
                        className={styles.verifyButton}
                        aria-label="Verify Code"
                    >
                        Verify
                    </button>
                </div>
                {!isCodeValid && <p className={styles.errorText}>Please enter a valid 6-digit code.</p>}
                <div className={styles.resendSection}>
                    {resendEnabled ? (
                        <button
                            onClick={handleResendCode}
                            className={styles.resendButton}
                            aria-label="Resend Verification Code"
                            disabled={resendCount >= 5}
                        >
                            Resend Code
                        </button>
                    ) : (
                        <p className={styles.timerText}>Resend code in {resendTimer} seconds</p>
                    )}
                    <p className={styles.attemptsText}>Attempts remaining: {5 - resendCount}</p>
                </div>
                <div className={styles.helpSection}>
                    <p>Didn't receive the code? Check your spam folder or contact our support team.</p>
                    <a href="/support" className={styles.supportLink}>Get Help</a>
                </div>
            </section>
        </div>
    );
};

export default VerificationPage;
