import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { FaUserTie, FaChartLine, FaBriefcase } from 'react-icons/fa';
import styles from './Home.module.css';

const Home = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <main className={styles.container}>
            <section className={styles.hero}>
                <div className={styles.heroContent}>
                    <h1 className={styles.title}>Elevate Your Career with Hire Me</h1>
                    <p className={styles.subtitle}>
                        Build standout profiles, connect with top employers, and accelerate your professional growth.
                    </p>
                    {!isAuthenticated ? (
                        <div className={styles.ctaGroup}>
                            <Link to="/signup" className={`${styles.button} ${styles.primaryButton}`}>
                                Start Your Career Journey
                            </Link>
                            <Link to="/login" className={`${styles.button} ${styles.secondaryButton}`}>
                                Sign In
                            </Link>
                        </div>
                    ) : (
                        <p className={styles.authMessage}>Welcome back! Ready to update your profile?</p>
                    )}
                </div>
                <div className={styles.heroImage}>
                    {/* Placeholder for hero image */}
                    <img src="/api/placeholder/500/300" alt="Professional networking" />
                </div>
            </section>

            <section className={styles.features}>
                <div className={styles.featureItem}>
                    <FaUserTie className={styles.featureIcon} />
                    <h3>AI-Powered Resume Builder</h3>
                    <p>Create compelling resumes tailored to your industry</p>
                </div>
                <div className={styles.featureItem}>
                    <FaChartLine className={styles.featureIcon} />
                    <h3>Skill Assessment Tools</h3>
                    <p>Identify your strengths and areas for improvement</p>
                </div>
                <div className={styles.featureItem}>
                    <FaBriefcase className={styles.featureIcon} />
                    <h3>Direct Employer Connections</h3>
                    <p>Match with companies seeking your unique skillset</p>
                </div>
            </section>

            <section className={styles.testimonials}>
                <h2>Success Stories</h2>
                <div className={styles.testimonialGrid}>
                    {/* Placeholder for testimonials */}
                    <div className={styles.testimonialItem}>
                        <img src="/api/placeholder/100/100" alt="User avatar" className={styles.avatar} />
                        <p>"Hire Me helped me land my dream job in just 2 weeks!"</p>
                        <span>- Sarah J., Software Engineer</span>
                    </div>
                    {/* Add more testimonials as needed */}
                </div>
            </section>

            <section className={styles.stats}>
                <div className={styles.statItem}>
                    <h3>10,000+</h3>
                    <p>Professional Profiles</p>
                </div>
                <div className={styles.statItem}>
                    <h3>500+</h3>
                    <p>Partner Companies</p>
                </div>
                <div className={styles.statItem}>
                    <h3>95%</h3>
                    <p>Success Rate</p>
                </div>
            </section>

            <section className={styles.cta}>
                <h2>Ready to Take the Next Step in Your Career?</h2>
                <Link to="/signup" className={`${styles.button} ${styles.primaryButton}`}>
                    Get Started Now
                </Link>
            </section>

            <footer className={styles.footer}>
                <div className={styles.footerContent}>
                    <p>&copy; 2024 Hire Me. All rights reserved.</p>
                    <div className={styles.footerLinks}>
                        <Link to="/about">About</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                    </div>
                </div>
            </footer>
        </main>
    );
};

export default Home;