import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm';
import styles from './SignUp.module.css';

const SignUp = () => {
    return (
        <main className={styles.container}>
            <section className={styles.signupSection}>
                <div className={styles.formContainer}>
                    <h1 className={styles.title}>Join Hire Me</h1>
                    <p className={styles.subtitle}>Create your professional profile and unlock new opportunities</p>
                    <SignUpForm />
                    <div className={styles.loginPrompt}>
                        <p>Already have an account? <Link to="/login" className={styles.loginLink}>Log in</Link></p>
                    </div>
                </div>
                <div className={styles.benefitsContainer}>
                    <h2>Why Join Hire Me?</h2>
                    <ul className={styles.benefitsList}>
                        <li>Build a standout professional profile</li>
                        <li>Connect with top employers in your field</li>
                        <li>Access personalized job recommendations</li>
                        <li>Enhance your skills with tailored resources</li>
                    </ul>
                    <div className={styles.trustedBy}>
                        <p>Trusted by professionals from:</p>
                        <div className={styles.companyLogos}>
                            {/* Add placeholder images for company logos */}
                            <img src="/api/placeholder/50/30" alt="Company 1" />
                            <img src="/api/placeholder/50/30" alt="Company 2" />
                            <img src="/api/placeholder/50/30" alt="Company 3" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SignUp;