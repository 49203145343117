import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { toast } from 'react-toastify';
import styles from './Login.module.css'; // Assuming you'll create a matching CSS module

const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    function handleLoginSuccess(isVerified) {
        setLoading(true);
        if (isVerified) {
            navigate('/dashboard');
        } else {
            toast.info('Please verify your email to access all features.');
            navigate('/verify-email');
        }
        setLoading(false);
    }

    function handleLoginFailure(error) {
        toast.error('Unable to log in. Please check your credentials and try again.');
        setLoading(false);
    }

    return (
        <main className={styles.container}>
            <section className={styles.loginSection}>
                <div className={styles.formContainer}>
                    <h1 className={styles.title}>Welcome Back</h1>
                    <p className={styles.subtitle}>Log in to access your professional profile and opportunities</p>
                    
                    {loading ? (
                        <div className={styles.loadingIndicator}>
                            Logging in...
                        </div>
                    ) : (
                        <LoginForm 
                            onLoginSuccess={handleLoginSuccess} 
                            onLoginFailure={handleLoginFailure} 
                        />
                    )}
                    
                    <div className={styles.signupPrompt}>
                        <p>New to Hire Me? <Link to="/signup" className={styles.signupLink}>Create an account</Link></p>
                    </div>
                </div>
                <div className={styles.benefitsContainer}>
                    <h2>Why Choose Hire Me?</h2>
                    <ul className={styles.benefitsList}>
                        <li>Access to exclusive job opportunities</li>
                        <li>AI-powered resume optimization</li>
                        <li>Direct connections with top employers</li>
                        <li>Personalized career growth insights</li>
                    </ul>
                </div>
            </section>
        </main>
    );
};

export default Login;