// DashboardPage.jsx
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import AuthContext from '../context/AuthContext';
import styles from './DashboardPage.module.css';

const DashboardPage = () => {
    const { user } = useContext(AuthContext);

    return (
        <main className={styles.dashboardContainer}>
            <header className={styles.dashboardHeader}>
                <h1>Welcome back, {user?.name || 'Professional'}!</h1>
                <p>Your career growth hub - all in one place</p>
            </header>

            <section className={styles.quickActions}>
                <h2>Quick Actions</h2>
                <div className={styles.actionButtons}>
                    <Link to="/profile" className={styles.actionButton} aria-label="Update Profile">Update Profile</Link>
                    <Link to="/job-search" className={styles.actionButton} aria-label="Search Jobs">Search Jobs</Link>
                    <Link to="/network" className={styles.actionButton} aria-label="Grow Network">Grow Network</Link>
                </div>
            </section>

            <Dashboard />

            <section className={styles.careerInsights}>
                <h2>Career Insights</h2>
                <div className={styles.insightCards}>
                    <div className={styles.insightCard}>
                        <h3>Profile Views</h3>
                        <p>Your profile was viewed 27 times this week</p>
                    </div>
                    <div className={styles.insightCard}>
                        <h3>Skill Trend</h3>
                        <p>React.js is in high demand in your area</p>
                    </div>
                    <div className={styles.insightCard}>
                        <h3>Salary Insights</h3>
                        <p>Average salary for your role: $85,000/year</p>
                    </div>
                </div>
            </section>

            <footer className={styles.dashboardFooter}>
                <p>Need help? <Link to="/support" className={styles.supportLink}>Contact our support team</Link></p>
            </footer>
        </main>
    );
};

export default DashboardPage;
