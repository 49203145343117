// ErrorPage.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './ErrorPage.module.css';

const ErrorPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // 模拟加载过程
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000); // 1秒后停止加载
        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className={styles.loadingOverlay}>
                <div className={styles.spinner}></div>
            </div>
        );
    }

    return (
        <main className={styles.errorContainer}>
            <section className={styles.errorContent}>
                <h1 className={styles.errorTitle}>404 - Page Not Found</h1>
                <p className={styles.errorMessage}>
                    Oops! It seems the page you're looking for doesn't exist or has been moved.
                </p>
                <div className={styles.errorActions}>
                    <Link to="/" className={styles.primaryButton} aria-label="Return to Homepage">
                        Return to Homepage
                    </Link>
                    <Link to="/contact" className={styles.secondaryButton} aria-label="Contact Support">
                        Contact Support
                    </Link>
                </div>
                <div className={styles.suggestedLinks}>
                    <h2>You might be interested in:</h2>
                    <ul>
                        <li><Link to="/jobs">Browse Job Opportunities</Link></li>
                        <li><Link to="/profile">Update Your Profile</Link></li>
                        <li><Link to="/resources">Career Resources</Link></li>
                    </ul>
                </div>
            </section>
        </main>
    );
};

export default ErrorPage;
